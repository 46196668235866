import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import Characteristic from '../components/Characteristic'
import Usps from '../components/Usps'
import Gallery from '../components/Gallery'
import Hint from '../components/Hint'
import Addresses from '../components/Addresses'

import '../scss/styles.scss'

const BellaAromatica = () => {
  return (
    <DefaultLayout
      title={siteData.bellaAromatica.title}
      description={siteData.bellaAromatica.description}
      activePage={Pages.BELLA_AROMATICA}
    >
      <Section className="headline">
        <Container>
          <h1 className="title">{siteData.bellaAromatica.headline}</h1>
          <h2 className="subheadline">{siteData.bellaAromatica.subheadline}</h2>
          <Paragraph className="protection">
            {siteData.bellaAromatica.protection}
          </Paragraph>
        </Container>
      </Section>

      <Usps
        imageSrc={`../${siteData.bellaAromatica.uspImg}`}
        usps={siteData.bellaAromatica.usps}
      />
      <Section>
        <Container>
          <Paragraph className="is-last">Benötigt ein Stützgerüst!</Paragraph>
        </Container>
      </Section>

      <Section className="characteristics">
        <Container>
          <Characteristic title="Bodenansprüche">
            „Bella Aromatica“ ® benötigt einen lockeren humosen Boden. Auf
            schweren Böden wie z.B. Lehmboden gedeiht sie nicht. Man kann leicht
            selbst eine geeignete Bodenmischung für die Himbeeren herstellen,
            auf der sie sich sehr gut bewurzeln: 3 Eimer Gartenboden oder
            zugekaufte Blumenerde, 1 Eimer Sand, 1 Eimer Kompost oder
            Pferdemist, alles gut mischen!). Mit dieser Erdmischung kann man
            auch einen kleinen Damm (40 cm hoch) aufschütten, auf dem die
            Himbeeren sehr gut wachsen.
          </Characteristic>
          <Characteristic title="Wuchs und Schnitt">
            „Bella Aromatica“ ® ist eine stark wachsende Herbsthimbeere. Auf
            guten humusreichen Himbeerböden erreicht sie eine Höhe von 1,50 bis
            2,00 m. Alle Ruten von „Bella Aromatica“ ® werden im späten Herbst
            (November) bodeneben zurückgeschnitten. Es dürfen keine Stummel
            bleiben, denn „Bella Aromatica“ ® treibt jedes Jahr neue Triebe aus
            dem Wurzelstock. Diese Triebe blühen im Juni und tragen ab September
            bis zum ersten Frost. Dieser Schnitt garantiert Maximalerträge von
            „Bella Aromatica“ ® und Gesundheit der Ruten. Die Früchte sind
            madenfrei!
            <br /> <br />
            Wenn nicht alle Triebe im Herbst zurückgeschnitten oder auch Stummel
            gelassen werden, sind diese Pflanzenteile zweijährig, sie blühen im
            Frühjahr und tragen im Juni. Das führt zu Rindenkrankheiten der
            Ruten und nur geringen Erträgen die das Ertragspotential der „Bella
            Aromatica“ ® nicht ausschöpfen.
          </Characteristic>
          <Characteristic title="Blüte">
            „Bella Aromatica“ ® blüht stark. Auffallend ist der Duft der Blüten,
            der viele Hummeln, Bienen und Wildbienen anlockt.
          </Characteristic>
          <Characteristic title="Frucht">
            Die Frucht von „Bella Aromatica“ ® ist länglich-oval, von schöner
            hellroter Farbe und mittlerem bis starken Glanz. Dunkelrote Früchte
            ohne Glanz und mit stumpfer matter Oberfläche sind überreif und
            schmecken nicht mehr. Deshalb sollte in der Haupternte jeden zweiten
            Tag geerntet werden. Die Früchte sind groß; im ersten Drittel der
            Ernteperiode beträgt das Durchschnittsgewicht je Frucht 5,78 g.
            <br /> <br />
            Die Frucht von „Bella Aromatica“ ® löst leicht vom Zapfen und ist
            relativ fest. Nach Beurteilung des Bundessortenamtes (Prüfstelle
            Wurzen) ist die Festigkeit der Früchte von „Bella Aromatica“ ® als
            mittel bis fest einzuschätzen. Die Früchte krümeln nicht und
            behalten nach der Ernte ihre schöne Form.
          </Characteristic>
          <Characteristic title="Geschmack">
            Der Geschmack von „Bella Aromatica“ ® ist sehr gut, süß, mit
            angenehmer ausgewogener Säure. Das feine Aroma ist mit dem der
            Waldhimbeere vergleichbar.
            <br /> <br />
            Die Himbeerfrucht als sogenannte Sammelsteinfrucht setzt sich aus
            vielen kleinen Früchtchen (Einzelsteinfrüchten) zusammen, die je
            nach Sorte mehr oder weniger Saft und den Samen enthalten. <br />
            Bei der Sorte „Bella Aromatica“ ® ist besonders erwähnenswert, dass
            die Himbeerfrucht sehr große Früchtchen mit sehr viel Saft enthält.
            Im Bundessortenamt (Prüfstelle Wurzen) wurde die Größe der
            Einzelsteinfrucht von „Bella Aromatica“ ® mit „groß bis sehr groß“
            und mit Note 8 (in Noten von 1 bis 9) angegeben, das bedeutet: sehr
            viel Saft, wenig Fruchthaut und wenig Kerne.
            <br /> <br />
            Aus den Früchten von „Bella Aromatica“ ® wird eine geschmacklich
            ganz ausgezeichnete Marmelade!
          </Characteristic>
          <Characteristic title="Ertrag">
            Der Ertrag von „Bella Aromatica“ ® ist sehr hoch.
            <br /> <br />
            Aufgrund des hohen Fruchtbehanges biegen sich die Ruten nach unten.
            „Bella Aromatica“ ® und müssen gestützt werden. Ein Gerüst wird
            dringend empfohlen. „Bella Aromatica“ ® bildet aus den Blattachseln
            Nebentriebe mit Früchten. In der Regel sind es 8 bis 10 Früchte Je
            Nebentrieb, die zu der hohen Ertragsleistung von „Bella Aromatica“ ®
            beitragen.
          </Characteristic>
          <Characteristic title="Erntezeit">
            September bis zum ersten Frost.
          </Characteristic>
          <Characteristic title="Krankheiten" isLast>
            Sehr widerstandsfähig! Bei Totalschnitt aller Ruten im Spätherbst
            keine Rutenerkrankungen und auch keine Maden in den Früchten. Nicht
            anfällig gegen Phytophthora-Wurzelfäule.
          </Characteristic>
        </Container>
      </Section>

      <Section>
        <Container>
          <Gallery images={siteData.bellaAromatica.images} />
        </Container>
      </Section>

      <Hint hint={siteData.bellaAromatica.hints[0]} />

      <Addresses
        title={siteData.bellaAromatica.licenseesTitle}
        type="licensees"
        addresses={siteData.bellaAromatica.licensees}
      />
    </DefaultLayout>
  )
}

export default React.memo(BellaAromatica)
